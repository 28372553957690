import React, { useState, useEffect } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import { shuffle } from 'lodash';
import { firestore } from '../firebaseConfig';
import { collection, getDocs } from 'firebase/firestore';
import { ClipLoader } from 'react-spinners';
import Sidebar from '../components/Sidebar';
import '../components/App.css';

const App = () => {
  const { dominioNome } = useParams();
  const navigate = useNavigate();
  const [dominios, setDominios] = useState([]);
  const [firestoreData, setFirestoreData] = useState([]);
  const [setores, setSetores] = useState([]);
  const [filtroBusca, setFiltroBusca] = useState('');
  const [setorSelecionado, setSetorSelecionado] = useState(null);
  const [currentPage, setCurrentPage] = useState(1);
  const [loading, setLoading] = useState(true);
  const [itemsPerPage] = useState(1000);

  const fetchData = async () => {
    try {
      setLoading(true);
      const querySnapshot = await getDocs(collection(firestore, 'dominios'));
      const data = [];
      const setoresSet = new Set();
      querySnapshot.forEach((doc) => {
        const dominio = { ...doc.data(), id: doc.id };
        data.push(dominio);
        setoresSet.add(dominio.setor);
      });
      setFirestoreData(data);
      setDominios(shuffle(data));
      setSetores(Array.from(setoresSet).sort());
    } catch (error) {
      console.error('Error fetching data:', error);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    fetchData();
  }, []);

  const handleConsultarClick = (dominio) => {
    navigate(`/contato/${dominio.nome}`);
  };

  const handleFiltroBusca = (value) => {
    setFiltroBusca(value.toLowerCase());
    setCurrentPage(1);
  };

  const handleSetorClick = (setor) => {
    setSetorSelecionado(setor);
    setFiltroBusca(setor.toLowerCase());
    setCurrentPage(1);
  };

  const filteredDominios = dominios.filter((dominio) => {
    const buscaSetor = filtroBusca ? dominio.setor.toLowerCase().includes(filtroBusca) : true;
    const buscaNome = filtroBusca ? dominio.nome.toLowerCase().includes(filtroBusca) : true;
    return buscaSetor || buscaNome;
  });

  const setoresFiltrados = setorSelecionado
    ? filteredDominios.filter((dominio) => dominio.setor === setorSelecionado)
    : filteredDominios;

  const indexOfLastItem = currentPage * itemsPerPage;
  const indexOfFirstItem = indexOfLastItem - itemsPerPage;
  const currentItems = setoresFiltrados.slice(indexOfFirstItem, indexOfLastItem);
  const totalPages = Math.ceil(setoresFiltrados.length / itemsPerPage);

  return (
    <div className="app-container">
      <div className="app-container">
        <Sidebar 
          setores={setores} 
          handleFiltroBusca={handleFiltroBusca} 
          handleSetorClick={handleSetorClick} 
        />
        
        <div className="card-container">
          {loading ? (
            <div className="loading-container">
              <ClipLoader color="#09f" size={50} />
              <p>Carregando domínios...</p>
            </div>
          ) : currentItems.length === 0 ? (
            <p>Nenhum domínio encontrado.</p>
          ) : (
            currentItems.map((dominio) => (
              <div className="card" key={dominio.id}>
                <h3>{dominio.nome.toLowerCase()}</h3>
                <p className="setor">{dominio.setor}</p>
                {/* <div className={`button button-${dominio.status.toLowerCase()}`}>                  
                </div> */}
                <div 
                  className="button button-consultar"
                  onClick={() => handleConsultarClick(dominio)}
                >
                  <p>Consultar</p>
                </div>
              </div>
            ))
          )}
        </div>

        {totalPages > 1 && (
          <div className="pagination-container">
            <button
              onClick={() => setCurrentPage((prev) => Math.max(prev - 1, 1))}
              disabled={currentPage === 1}
            >
              Anterior
            </button>
            <span>{`Página ${currentPage} de ${totalPages}`}</span>
            <button
              onClick={() => setCurrentPage((prev) => Math.min(prev + 1, totalPages))}
              disabled={currentPage === totalPages}
            >
              Próxima
            </button>
          </div>
        )}
      </div>
    </div>
  );
};

export default App;