import React, { useEffect, useState } from 'react';

const Agro5 = () => {
  const [showOverlay, setShowOverlay] = useState(true);

  useEffect(() => {
    // Remove o overlay após 5 segundos
    const timer = setTimeout(() => {
      setShowOverlay(false);
    }, 5000); // 5 segundos

    return () => clearTimeout(timer);
  }, []);

  return (
    <div style={{ position: 'relative', width: '100%', height: '100vh', overflow: 'hidden' }}>
      {/* Overlay com a mensagem */}
      {showOverlay && (
        <div
          style={{
            position: 'absolute',
            top: 0,
            left: 0,
            width: '100%',
            height: '100%',
            backgroundColor: 'rgba(0, 0, 0, 0.7)',
            color: 'white',
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            zIndex: 10,
            fontSize: '24px',
          }}
        >
          O domínio Agro5.com.br está à venda!
        </div>
      )}

      {/* Vídeo como background */}
      <iframe
        width="100%"
        height="100%"
        src="https://www.youtube.com/embed/XR0OYQN4c-M?autoplay=1&mute=1&controls=0&loop=1&playlist=XR0OYQN4c-M"
        title="AGRO 5.0 | Tecnologia no campo"
        frameBorder="0"
        allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
        allowFullScreen
        style={{
          position: 'absolute',
          top: '50%',
          left: '50%',
          width: '100vw',
          height: '100vh',
          transform: 'translate(-50%, -50%)',
          objectFit: 'cover',
          zIndex: 1,
        }}
      ></iframe>
    </div>
  );
};

export default Agro5;